import Image from '@atoms/Image';

function BalancedPortfolioScale(props) {
    return (
        <div className="md:min-h-[200px] xl:min-h-[250px] xl:mt-4 flex flex-col justify-end items-start relative w-full">
            <div
                className={` ${
                    props.activeSlide === 1 ? 'block' : 'hidden'
                } w-full `}
            >
                <Image
                    src="/images/homepage/brandUSP/balancedPortfolio/unbalancedPortfolioScaleBase.svg"
                    alt="unbalancedPortfolioScaleBase"
                    className="w-full md:block hidden"
                />
                <Image
                    src="/images/homepage/brandUSP/balancedPortfolio/unbalancedPortfolioScaleBase-mobile.svg"
                    alt="unbalancedPortfolioScaleBase"
                    className="w-full md:hidden block"
                />
            </div>
            <div
                className={`${
                    props.activeSlide === 2 ? 'block' : 'hidden'
                } w-full`}
            >
                <div className="relative">
                    <Image
                        src="/images/homepage/brandUSP/balancedPortfolio/balancedPortfolioScaleBase.svg"
                        alt="balancedPortfolioScaleBase"
                        className="w-full md:block hidden"
                    />
                    <Image
                        src="/images/homepage/brandUSP/balancedPortfolio/balancedPortfolioScaleBase-mobile.svg"
                        alt="balancedPortfolioScaleBase"
                        className="w-full md:hidden block"
                    />
                </div>
            </div>
        </div>
    );
}

export default BalancedPortfolioScale;
