import Image from "@atoms/Image";
import Portfolio from "./BalancedPortfolio";

export const sectionComponents = {
    higherReturns: {
        component: (
            <>
                <Image
                    src="/images/homepage/brandUSP/comparisonChart.svg"
                    alt="comparisonChart"
                    className="w-full mx-auto z-[10] rounded-l-lg md:block hidden shadow-[0px_54px_44px_0px_rgba(0,0,0,0.07)]"
                />
                <Image
                    src="/images/homepage/brandUSP/comparisonChart-mobile.svg"
                    alt="comparisonChart"
                    className="w-full mx-auto z-[10] rounded-l-lg md:hidden block shadow-[0px_54px_19px_0px_rgba(0,0,0,0.07)] h-full"
                />
            </>
        ),
        tabIcon: 'earn-higher-returns',
        cssClass: 'brand-graph-slide',
    },
    predictableReturns: {
        component: (
            <>
                <Image
                    src="./images/homepage/brandUSP/predictableReturnsTimeline.svg"
                    alt="predictableReturnsTimeline"
                    className="w-full mx-auto z-[10] md:block hidden rounded-l-lg shadow-[0px_54px_44px_0px_rgba(0,0,0,0.07)]"
                />
                <Image
                    src="./images/homepage/brandUSP/predictableReturnsTimeline-mobile.svg"
                    alt="predictableReturnsTimeline"
                    className="w-full mx-auto z-[10] md:hidden block rounded-l-lg shadow-[0px_54px_19px_0px_rgba(0,0,0,0.07)] h-full"
                />
            </>
        ),
        tabIcon: 'predictable-returns',
        cssClass: 'brand-payout-timeline',
    },
    goals: {
        component: (
            <>
                <Image
                    src="/images/homepage/brandUSP/our-goals.svg"
                    alt="comparisonChart"
                    className="w-full mx-auto z-[10] md:block hidden rounded-l-lg shadow-[0px_54px_44px_0px_rgba(0,0,0,0.07)]"
                />
                <Image
                    src="/images/homepage/brandUSP/our-goals-mobile.svg"
                    alt="comparisonChart"
                    className="w-full mx-auto z-[10] md:hidden block rounded-l-lg shadow-[0px_54px_19px_0px_rgba(0,0,0,0.07)] h-full"
                />
            </>
        ),
        tabIcon: 'one-platform',
        cssClass: 'brand-platform-goals',
    },
    balancedPortfolio: {
        component: <Portfolio />,
        tabIcon: 'balanced-portfolio',
        cssClass: 'brand-balanced-portfolio',
    },
};