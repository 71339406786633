import { useState } from 'react';
import BalancedPortfolioScale from './BalancedPortfolioScale';
import { Button } from '@ui/atoms/design-system';
import { PortfolioToggleButtons } from '@helpers/homepageData';

function Portfolio() {
    const [activeSlide, setActiveSlide] = useState(1);

    return (
        <div className="flex flex-col md:px-[52px] xl:px-24 h-full bg-basicWhite/40 shadow-[0px_54px_19px_0px_rgba(0,0,0,0.07)] md:shadow-[0px_54px_44px_0px_rgba(0,0,0,0.07)] p-2 md:p-6 rounded-l-lg">
            <ul className="flex absolute md:relative items-center max-w-min bg-primary-10 border rounded-xl z-50 p-1">
                {PortfolioToggleButtons.map((button, index) => (
                    <Button
                        onClick={() => setActiveSlide(index + 1)}
                        buttonText={button.title}
                        buttonType={
                            activeSlide === index + 1 ? 'primary' : 'tertiary'
                        }
                        key={index}
                        buttonSize="extraSmall"
                    />
                ))}
            </ul>
            <section
                className={`balanced-portfolio-illustration flex-1 relative justify-center ${
                    activeSlide === 2 ? 'scale-balanced' : 'scale-unbalanced'
                }`}
            >
                <BalancedPortfolioScale activeSlide={activeSlide} />
            </section>
        </div>
    );
}

export default Portfolio;
