import { useState } from 'react';
import { brandUsp } from '@helpers/homepageData';
import HomepageHeading from '../HomepageHeading';
import { Image, LazyImage, Text } from '@atoms/index';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { sectionComponents } from './SectionComponent';
const slideCssClasses = 'slide-wrapper w-full relative';
const footerTextCssClass =
    'relative p6-regular md:p5-regular text-basicBlack h-20 xl:h-10 pr-4';

function BrandUSP({ topSpacing = 'mt-12 md:mt-28' }) {
    const [currentSlide, setCurrentSlide] = useState(1);
    const [prevSlide, setPrevSlide] = useState(0);
    const { width } = useWindowDimensions();

    const showSlide = (newSlideNumber) => {
        if (currentSlide === newSlideNumber) {
            return;
        }
        setPrevSlide(currentSlide);
        setCurrentSlide(newSlideNumber);
    };

    return (
        <section className="relative">
            <Image
                src="/images/homepage/brandUSP/secondary-glow.svg"
                className={`absolute left-0 -top-80 md:block hidden md:max-w-[510px]`}
            />
            <Image
                src="/images/homepage/secondary-rectangle-glow.svg"
                className={`absolute left-0 -bottom-122 md:block hidden md:max-w-[510px] z-50`}
            />
            <div
                className={`w-full mx-auto max-w-screen-maxScreen xl:px-14 ${topSpacing}`}
            >
                <div className="relative z-50 flex flex-col items-center px-0 m-auto xl:flex-row">
                    <div
                        className={`slide-${currentSlide} xl:w-1/5 w-full px-4 rounded-b-xl xl:rounded-none xl:shadow-none shadow-[0px_6px_18px_0px_rgba(11,7,110,0.07)] relative flex xl:flex-col flex-row justify-center z-50 bg-basicWhite xl:bg-transparent`}
                    >
                        <div className="bg-basicWhite active-slide-indicator xl:block hidden items-center justify-end px-[20px] absolute xl:w-[120%] top-0  xl:h-[68px] rounded-lg">
                            <Image
                                src="/images/homepage/arrow-secondary.svg"
                                alt="leftArrow"
                                className="xl:block hidden w-[14px] h-4"
                            />
                        </div>
                        {brandUsp.map((slide, index) => {
                            return (
                                <div
                                    key={index}
                                    className="xl:w-full w-1/4 xl:h-[68px] xl:mb-[16px] flex items-center z-[99]"
                                >
                                    <div
                                        className={`${
                                            currentSlide === index + 1
                                                ? `slide-${currentSlide}`
                                                : ''
                                        } cursor-pointer w-full z-50 relative h-full xl:h-auto`}
                                        onClick={() => {
                                            showSlide(index + 1);
                                        }}
                                    >
                                        <div
                                            className={`h-full menu-item w-full px-1 py-2 md:py-4 xl:py-0 z-[999] ${
                                                currentSlide === index + 1
                                                    ? 'active-menu-item  xl:justify-between bg-secondary-20 xl:bg-basicWhite'
                                                    : 'xl:border-none  border-b-4 border-basicWhite'
                                            } flex flex-col justify-start`}
                                        >
                                            <div className="flex justify-center mb-1 mx-auto xl:hidden">
                                                <LazyImage
                                                    className="w-8 h-8 relative"
                                                    src={`/images/homepage/brandUSP/${
                                                        sectionComponents[
                                                            slide.id
                                                        ].tabIcon
                                                    }-${
                                                        currentSlide ===
                                                        index + 1
                                                            ? 'active'
                                                            : 'inactive'
                                                    }.svg`}
                                                />
                                            </div>
                                            <h3
                                                className={`${
                                                    currentSlide === index + 1
                                                        ? 'p6-medium md:p5-semibold'
                                                        : 'p6-regular md:p5-regular'
                                                } text-basicBlack xl:text-primary-500 xl:text-left text-center px-2 md:px-0`}
                                            >
                                                {width >= 1280
                                                    ? slide.title
                                                    : slide.titleMobile}
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="xl:w-4/5 w-full mx-auto xl:mt-[0]">
                        <section
                            className={`flex relative ${
                                currentSlide > prevSlide
                                    ? 'slide-right-in-left'
                                    : 'slide-left-in-right'
                            } slide-${prevSlide}-out slide-${currentSlide}-in slide-${currentSlide} bg-primary-10 xl:rounded-3xl overflow-hidden`}
                        >
                            {brandUsp.map((slide, index) => {
                                const slideSection =
                                    sectionComponents[slide.id];
                                return (
                                    <section
                                        key={slide.id}
                                        className={`${slideCssClasses} ${slideSection.cssClass} relative flex flex-col pl-4 py-6 md:px-6 xl:!pr-0 xl:!pl-24 md:py-8`}
                                    >
                                        <div className="relative flex flex-col justify-between h-full gap-6">
                                            <HomepageHeading
                                                headline={
                                                    slide.content.headline
                                                }
                                                htmlTag='h4'
                                                headlineColorClass="text-primary-500"
                                                headlineFontClass={`p3-medium md:h4-semibold`}
                                            />
                                            <div className="flex flex-col gap-6 h-full">
                                                {slideSection.component}
                                                <Text
                                                    htmlTag="p"
                                                    className={` ${
                                                        currentSlide ===
                                                        index + 1
                                                            ? 'block'
                                                            : 'hidden'
                                                    } ${footerTextCssClass}`}
                                                    content={
                                                        slide.content.footerText
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </section>
                                );
                            })}
                        </section>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default BrandUSP;
